<template>
    <loading-animation v-if="DataLoading"></loading-animation>
    <div v-else class="ml-3 mr-3">
        <div class="fl-te-c mb-3">
            <h4>Entry History</h4>
            <btn size="sm" @click="$router.push('/finance/entry/'+ id +'/details/')" text="Back"></btn>
        </div>
       <history-page :data="data"></history-page>
    </div>
</template>

<script>

import axios from 'secure-axios';
import urls from '../../../../../data/urls';
import HistoryPage from '../../../../CommonComponents/EntryHistoryPage';

export default {
    name       : 'EntryHistory',
    components : { HistoryPage },
    data () {
        return {
            DataLoading : false,
            id          : this.$route.params.id,
            data        : ''
        };
    },
    mounted () {
        this.loadDetails();
    },
    methods : {
        async loadDetails () {
            const that = this;
            that.DataLoading = true;
            const response = await axios.form(urls.financeAdmin.entryHistory, { entry_id : that.id });
            const json = response.data;
            if (json.error === false) {
                that.data = json.data;
                that.DataLoading = false;
            } else {
                that.$notify('Details not found.. Please try again later', 'Error', {
                    type : 'danger'
                });
                that.DataLoading = false;
            }
        }
    }
};
</script>
